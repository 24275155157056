.support-record-card {
    padding: 30px 30px 20px 30px;

}

.supporter-title {
    color: #4a4a4a;
}

.supporter-card {
    cursor: pointer;
    margin: 20px 0 20px;
}

.supporters-add-container {
    margin: -45px 150px 0 0;
}

.supporter-address {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
}

.supporter-created {
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    color: #757575;
}

.supporter-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    text-align: left;
    color: rgba(0, 0, 0, 0.54);
}

.supporters-filters-label {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.75;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
}

.supporters-empty {
    font-size: 18px;
}

.supporters-empty-top {
    font-size: 22px;
    font-weight: 500;
}

.supporters-campaign-name {
    font-weight: 500;
}

.supporter-sync-icon {
    width: 25px;
    height: auto;
}

.supporter-sync-status {
    font-size: 15px;
}

.supporter-hide-info {
    margin-left: -10px;
    color: #757575;
}

.supporter-letters-icon {
    width: 20px;
    height: auto;
    margin-top: 2px;
}