.home-toolbar-title {
    font-size: 20px;
    font-weight: 700;
    /*text-transform: uppercase;*/
    color: #fff;
    /*margin-left: 30px;*/
    text-align: left;
    margin-left: 10px;
}

.home-content-container {
    /*margin-left: var(--nav-width);*/
    height: 100vh;
}

.home-toolbar-menu-btn {
    margin-left: -10px !important;
}

.home-nav-container {
    /*position: fixed;*/
    /*top: 0;*/
    /*left: 0;*/
    z-index: 1101;
}

.home-toolbar-title-container {
    margin-left: 15px;
}

.home-main-content {
    background-color: var(--color-content-bg);
    /*padding-top: 65px;*/
}

.home-toolbar-logo {
    width: 265px;
    height: auto;
}