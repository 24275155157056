.campaign-card {
    margin-top: 20px;
}

.campaign-name {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
}

.campaign-office {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    text-align: left;
    margin-top: 5px;
    color: rgba(0, 0, 0, 0.54);
}

.campaign-description {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.33;
    color: rgba(0, 0, 0, 0.54);
}