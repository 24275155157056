.support-records-container {
    padding: 30px;
}

.support-records-campaign-name {
    font-size: 16px;
}

.support-records-add-fab {
    z-index: 1100;
    position: fixed;
    right: 15px;
    top: 80px;
}