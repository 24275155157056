@import url('https://fonts.googleapis.com/css?family=Nothing+You+Could+Do');

:root {
  /* Common Colors */
  --color-blue: #00b4e8;
  --color-green: #4db04a;
  --color-yellow: #ffd400;
  --color-darkest-gray: #363636;
  --color-gray: #6D6D6D;
  --color-medium-gray: #A4A4A4;
  --color-light-gray: #D5D5D5;
  --color-lightest-gray: #F2F4F5;
  --nav-width: 320px;
  --color-content-bg: #f5f5f5;
}

.roboto {
  font-family: 'Roboto', sans-serif;
}

.montserat {
  font-family: 'Montserrat', sans-serif;
}

.nothing {
  font-family: 'Nothing You Could Do', cursive;
}

.page-header {
  font-size: 28px;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.error-label {
  color: red;
  font-size: 14px;
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/montserrat/Montserrat-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/montserrat/Montserrat-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/montserrat/Montserrat-Bold.ttf');
  font-weight: 700;
}