.signatures-container {
    overflow-x: scroll;
}

.signature-display {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.signature-container {
    padding: 10px 20px 0 20px;
    /*margin-right: 15px;*/
    min-width: 150px;
}

.signature-name {
    font-size: 14px;
    font-weight: 500;
    /*line-height: 1.71;*/
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
}

.signature-address {
    font-size: 14px;
    font-weight: 500;
    /*line-height: 1.71;*/
    text-align: left;
    color: rgba(0, 0, 0, 0.54);
}