.contribution-container {
    padding: 20px;
}

.contribution-card {
    padding: 40px;
}

.contribution-title {
    font-size: 34px;
    font-weight: 100;
    line-height: 0.93;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
}

.contribution-subtitle {
    font-size: 18px;
    line-height: 1.4;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 20px;
}

.contribution-amount-container {
    display: flex;
}

.contribution-amount {
    font-size: 40px !important;
    text-align: right;
}

.contribution-amount-dollar {
    font-size: 26px;
    font-weight: 600;
    line-height: 0.93;
    text-align: left;
    margin-top: 5px;
    color: #202020;
}

.contribution-payment-method-container {
    margin-top: 0px;
}

.contribution-cc-logos {
    width: 160px;
    height: auto;
}

.contribution-payment-method-label {
    font-size: 14px;
    line-height: 1.75;
    margin-right: 20px;
    color: rgba(0, 0, 0, 0.87);
}

.contribution-see-levels-button-container {
    margin-top: 10px;
}

.contribution-levels-image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 90vh;
    width: 100%;
    flex: 1;
}

textarea:focus, input:focus{
    outline: none;
}

input {
    border: none;
}