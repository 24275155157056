.campaigns-container {
    /*height: 20000px;*/
    padding: 35px;
}

.campaigns-add-fab {
    z-index: 1100;
    position: fixed;
    right: 105px;
    top: 35px;
}