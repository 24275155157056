.supporter-info-container {
    padding: 25px;
}

.supporter-info-card {
    padding: 25px;
}

.supporter-info-title {
    color: #1f1f1f;
    font-size: 18px;
    font-weight: old;
    line-height: 1.33;
}

.supporter-info-receive-text-messages-title {
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    margin-top: 10px;
}

.supporter-info-receive-text-messages-subtitle {
    color: rgba(0, 0, 0, 0.54);
    font-size: 10px;
    text-align: left;
    margin-top: 0px;
}

.supporter-info-disclaimer {
    font-size: 10px;
    color: rgba(0, 0, 0, 0.54);
    margin-left: 5px;
    cursor: pointer;
}