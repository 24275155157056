.scoty-color-container {
    position: absolute;
    top: 10px;
    right: 10px;
}

.scoty-pen-size-container {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 200px;
    padding: 20px;
    background-color: white;
}

.scoty-clickers {
    position: absolute;
    top: 10px;
    right: 10px;
}

.scoty-pen-size-slider {
    width: 200px;
    overflow-y: visible;
}