.billing-info-container {
    width: 600px;
}

.billing-info-title {
    margin-bottom: 10px;
}

.billing-info-stripe-img {
    height: 45px;
    width: auto;
}