.support-statement-infographic-container {
    flex: 2;
    padding: 25px 25px 12px;
}

.support-statement-signatures-container {
    flex: 1;
    padding: 15px 25px 25px;
}

.support-statement-signatures-header {
    background-color: #ffd400;
    color: rgba(0, 0, 0, 0.8);
    padding: 15px 20px;
}

.support-statement-img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 0%;
}