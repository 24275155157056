.login-container {
    display: flex;
    height: 100vh;
}

.login-background {
    background-image: url(../../img/login-bg.jpg);
    background-size: cover;
    background-position: 50% 50%;
    position: absolute;
    filter: blur(5px);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.login-card {
    width: 500px;
    padding: 40px;
    z-index: 2;
}

.login-row {
    margin-top: 15px;
}

.login-buttons {
    margin-top: 30px;
}

.login-logo {
    width: 315px;
    height: auto;
}

.login-title {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 35px;
}

.login-helper-text {
    font-size: 16px;
    color: black;
    margin: 15px 0;
}