.carousel-container {
    /*overflow-x: scroll;*/
    overflow: hidden;
    width: 100%;
    position: relative;
}

.carousel-inner-container {
    transition: 0.3s linear all;
}

.carousel-page {
    display: inline-block;
}

.carousel-left-arrow {
    position: absolute;
    top: 45%;
    left: 10px;
    background-color: rgba(0,0,0,0.25);
    border-radius: 30px;
}

.carousel-right-arrow {
    position: absolute;
    top: 45%;
    right: 10px;
    background-color: rgba(0,0,0,0.25);
    border-radius: 30px;
}

.carousel-indicators {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 20px;
}

.carousel-indicator {
    height: 7px;
    width: 7px;
    border-radius: 7px;
    border: #4db04a 2px solid;
}

.carousel-indicator-selected {
    background-color: #4db04a;
}