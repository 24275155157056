.survey-container {
    padding: 20px;
}

.survey-question-text {
    font-size: 18px;
    line-height: 1.27;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
}

.survey-option-text {
    font-size: 14px;
    line-height: 1.5;
    text-align: left;
    color: #000000;
}

.survey-card {
    overflow-y: scroll;
    padding: 30px;
}

.survey-todo-card {
    padding: 30px;
    margin-bottom: 20px;
}

.survey-todo-header {
    font-size: 18px;
    font-weight: bold;
}