.thank-you-container {
    padding: 20px;
}

.thank-you-title {
    font-size: 60px;
}

.thank-you-smile {
    width: 81px;
    height: auto;
    margin-left: 30px;
}

.thank-you-text {
    font-size: 16px;
    text-align: center;
    padding: 0 116px;
    margin-top: 30px;
}

.thank-you-logo-container {
    margin-top: 50px;
}

.thank-you-logo {
    height: auto;
    width: 250px;
}

.thank-you-should-know {
    text-align: center;
    font-size: 30px;
    margin-top: 15px;
}

.thank-you-card {
    padding: 40px;
}