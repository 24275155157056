.infographics-container {
    overflow-y: hidden;
}

.infographics-img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    min-height: 90vh;
    /*min-width: 400px;*/
    flex: 1;
}

.infographics-indicator {
    height: 7px;
    width: 7px;
    border-radius: 7px;
    border: #4db04a 2px solid;
}

.infographics-indicator-selected {
    background-color: #4db04a;
}

.infographics-close-container {
    position: absolute;
    top: 20px;
    right: 20px;
}

.infographics-indicators {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 20px;
}

