@import url(https://fonts.googleapis.com/css?family=Nothing+You+Could+Do);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  --tw-shadow: 0 0 #0000;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}
.container {
  width: 100%;
}
@media (min-width: 640px) {

  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {

  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {

  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {

  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {

  .container {
    max-width: 1536px;
  }
}
.invisible {
  visibility: hidden;
}
.visible {
  visibility: visible;
}
.relative {
  position: relative;
}
.static {
  position: static;
}
.absolute {
  position: absolute;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
}
.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.left-\[400px\] {
  left: 400px;
}
.bottom-5 {
  bottom: 1.25rem;
}
.left-5 {
  left: 1.25rem;
}
.ml-5 {
  margin-left: 1.25rem;
}
.mt-20 {
  margin-top: 5rem;
}
.mr-8 {
  margin-right: 2rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.ml-10 {
  margin-left: 2.5rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.flex {
  display: flex;
}
.hidden {
  display: none;
}
.h-full {
  height: 100%;
}
.w-80 {
  width: 20rem;
}
.w-\[400px\] {
  width: 400px;
}
.w-full {
  width: 100%;
}
.flex-1 {
  flex: 1 1 0%;
}
.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.cursor-pointer {
  cursor: pointer;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.overflow-x-scroll {
  overflow-x: scroll;
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.border {
  border-width: 1px;
}
.border-0 {
  border-width: 0px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-solid {
  border-style: solid;
}
.bg-\[\#FAFAFA\] {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
}
.p-4 {
  padding: 1rem;
}
.p-6 {
  padding: 1.5rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.pr-6 {
  padding-right: 1.5rem;
}
.text-center {
  text-align: center;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.font-bold {
  font-weight: 700;
}
.text-\[gray\] {
  --tw-text-opacity: 1;
  color: rgba(128, 128, 128, var(--tw-text-opacity));
}
.underline {
  text-decoration: underline;
}
.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.filter {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.campaign-card {
    margin-top: 20px;
}

.campaign-name {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
}

.campaign-office {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    text-align: left;
    margin-top: 5px;
    color: rgba(0, 0, 0, 0.54);
}

.campaign-description {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.33;
    color: rgba(0, 0, 0, 0.54);
}
.stats-container {
    padding: 25px;
}
.error {
    font-size: 14px;
    color: red;
    font-style: italic;
}
.todo-supporter-name {
    font-weight: bold;
}

.todo-supporter-contribution-amount {
    color: var(--color-green);
    font-weight: bold;
}

.paint-canvas {
    pointer-events: all;
}
.drawing-layer-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
}

.scoty-static-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
}
.clickers-container {
    /*padding: 20px;*/
}

.clicker-row-value {
    width: 30px;
}

.img-slide-container {
    /*padding: 20px;*/
}

.img-slide-inner {
    padding: 20px;
}

.img-slide-paper {
    padding: 5px;
}

.img-slide-img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 0%;
    width: 100%;
}
.contribution-container {
    padding: 20px;
}

.contribution-card {
    padding: 40px;
}

.contribution-title {
    font-size: 34px;
    font-weight: 100;
    line-height: 0.93;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
}

.contribution-subtitle {
    font-size: 18px;
    line-height: 1.4;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 20px;
}

.contribution-amount-container {
    display: flex;
}

.contribution-amount {
    font-size: 40px !important;
    text-align: right;
}

.contribution-amount-dollar {
    font-size: 26px;
    font-weight: 600;
    line-height: 0.93;
    text-align: left;
    margin-top: 5px;
    color: #202020;
}

.contribution-payment-method-container {
    margin-top: 0px;
}

.contribution-cc-logos {
    width: 160px;
    height: auto;
}

.contribution-payment-method-label {
    font-size: 14px;
    line-height: 1.75;
    margin-right: 20px;
    color: rgba(0, 0, 0, 0.87);
}

.contribution-see-levels-button-container {
    margin-top: 10px;
}

.contribution-levels-image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 90vh;
    width: 100%;
    flex: 1 1;
}

textarea:focus, input:focus{
    outline: none;
}

input {
    border: none;
}
.contribution-levels-image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 90vh;
    width: 100%;
    flex: 1 1;
}
.supporter-info-confirmation-dialog-signature-container {
    /*width: 600px;*/
}

.supporter-info-confirmation-dialog {
    /*width: 90%;*/
}

.supporter-info-confirmation-dialog-title {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.87);
}

.supporter-info-confirmation-dialog-info {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
}

.supporter-info-confirmation-dialog-disclaimer {
    font-size: 10px;
    color: rgba(0, 0, 0, 0.54);
    position: absolute;
    bottom: 30px;
    left: 30px;
    right: 30px;
}

.supporter-info-confirmation-dialog-signature-x {
    font-size: 20px;
}

.supporter-info-confirmation-dialog-signature-label {
    font-size: 18px;
    line-height: 1.33;
    text-align: left;
    color: #a0a0a0;
    margin: 10px 0 10px;
}

/***
 Signature Shiz
 */

.m-signature-pad {
    position: relative;
    font-size: 10px;
    border: 1px solid black;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.m-signature-pad--body {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    /*border: 1px solid #f4f4f4;*/
}

.m-signature-pad--body
canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /*border-radius: 4px;*/
    /*box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;*/
}

.m-signature-pad--footer {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
    height: 40px;
}

.m-signature-pad--footer
.description {
    color: #C3C3C3;
    text-align: center;
    font-size: 1.2em;
    margin-top: 1.8em;
}

.m-signature-pad--footer
.button {
    position: absolute;
    bottom: 0;
}

.m-signature-pad--footer
.button.clear {
    left: 0;
    display: none;
}

.m-signature-pad--footer
.button.save {
    right: 0;
}


@media screen and (max-width: 2024px) {
    .m-signature-pad {
        height: 100%;
        min-height: 350px;
        /*margin: 5px;*/
    }
    #github {
        display: none;
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .m-signature-pad {
        /*margin: 20px;*/
    }
}

@media screen and (max-height: 320px) {
    .m-signature-pad--body {
        left: 0;
        right: 0;
        top: 0;
        bottom: 32px;
    }
    .m-signature-pad--footer {
        left: 20px;
        right: 20px;
        bottom: 4px;
        height: 28px;
    }
    .m-signature-pad--footer
    .description {
        font-size: 1em;
        margin-top: 1em;
    }
}
.billing-info-container {
    width: 600px;
}

.billing-info-title {
    margin-bottom: 10px;
}

.billing-info-stripe-img {
    height: 45px;
    width: auto;
}
.signatures-container {
    overflow-x: scroll;
}

.signature-display {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.signature-container {
    padding: 10px 20px 0 20px;
    /*margin-right: 15px;*/
    min-width: 150px;
}

.signature-name {
    font-size: 14px;
    font-weight: 500;
    /*line-height: 1.71;*/
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
}

.signature-address {
    font-size: 14px;
    font-weight: 500;
    /*line-height: 1.71;*/
    text-align: left;
    color: rgba(0, 0, 0, 0.54);
}
.support-statement-infographic-container {
    flex: 2 1;
    padding: 25px 25px 12px;
}

.support-statement-signatures-container {
    flex: 1 1;
    padding: 15px 25px 25px;
}

.support-statement-signatures-header {
    background-color: #ffd400;
    color: rgba(0, 0, 0, 0.8);
    padding: 15px 20px;
}

.support-statement-img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 0%;
}
.privacy-content-container {
    background-color: #ffffff;
    position: relative;
    height: auto;
    overflow-y: scroll;
    padding: 30px;
}
.supporter-info-container {
    padding: 25px;
}

.supporter-info-card {
    padding: 25px;
}

.supporter-info-title {
    color: #1f1f1f;
    font-size: 18px;
    font-weight: old;
    line-height: 1.33;
}

.supporter-info-receive-text-messages-title {
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    margin-top: 10px;
}

.supporter-info-receive-text-messages-subtitle {
    color: rgba(0, 0, 0, 0.54);
    font-size: 10px;
    text-align: left;
    margin-top: 0px;
}

.supporter-info-disclaimer {
    font-size: 10px;
    color: rgba(0, 0, 0, 0.54);
    margin-left: 5px;
    cursor: pointer;
}
.thank-you-container {
    padding: 20px;
}

.thank-you-title {
    font-size: 60px;
}

.thank-you-smile {
    width: 81px;
    height: auto;
    margin-left: 30px;
}

.thank-you-text {
    font-size: 16px;
    text-align: center;
    padding: 0 116px;
    margin-top: 30px;
}

.thank-you-logo-container {
    margin-top: 50px;
}

.thank-you-logo {
    height: auto;
    width: 250px;
}

.thank-you-should-know {
    text-align: center;
    font-size: 30px;
    margin-top: 15px;
}

.thank-you-card {
    padding: 40px;
}

.survey-container {
    padding: 20px;
}

.survey-question-text {
    font-size: 18px;
    line-height: 1.27;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
}

.survey-option-text {
    font-size: 14px;
    line-height: 1.5;
    text-align: left;
    color: #000000;
}

.survey-card {
    overflow-y: scroll;
    padding: 30px;
}

.survey-todo-card {
    padding: 30px;
    margin-bottom: 20px;
}

.survey-todo-header {
    font-size: 18px;
    font-weight: bold;
}
.carousel-container {
    /*overflow-x: scroll;*/
    overflow: hidden;
    width: 100%;
    position: relative;
}

.carousel-inner-container {
    transition: 0.3s linear all;
}

.carousel-page {
    display: inline-block;
}

.carousel-left-arrow {
    position: absolute;
    top: 45%;
    left: 10px;
    background-color: rgba(0,0,0,0.25);
    border-radius: 30px;
}

.carousel-right-arrow {
    position: absolute;
    top: 45%;
    right: 10px;
    background-color: rgba(0,0,0,0.25);
    border-radius: 30px;
}

.carousel-indicators {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 20px;
}

.carousel-indicator {
    height: 7px;
    width: 7px;
    border-radius: 7px;
    border: #4db04a 2px solid;
}

.carousel-indicator-selected {
    background-color: #4db04a;
}
.infographics-container {
    overflow-y: hidden;
}

.infographics-img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    min-height: 90vh;
    /*min-width: 400px;*/
    flex: 1 1;
}

.infographics-indicator {
    height: 7px;
    width: 7px;
    border-radius: 7px;
    border: #4db04a 2px solid;
}

.infographics-indicator-selected {
    background-color: #4db04a;
}

.infographics-close-container {
    position: absolute;
    top: 20px;
    right: 20px;
}

.infographics-indicators {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 20px;
}


.support-record-details-next-button-container {
    position: fixed;
    bottom: 40px;
    right: 40px;
}
.scoty-color-container {
    position: absolute;
    top: 10px;
    right: 10px;
}

.scoty-pen-size-container {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 200px;
    padding: 20px;
    background-color: white;
}

.scoty-clickers {
    position: absolute;
    top: 10px;
    right: 10px;
}

.scoty-pen-size-slider {
    width: 200px;
    overflow-y: visible;
}
.loading-container {
    padding-top: 30px;
}
.campaigns-container {
    /*height: 20000px;*/
    padding: 35px;
}

.campaigns-add-fab {
    z-index: 1100;
    position: fixed;
    right: 105px;
    top: 35px;
}

.support-record-card {
    padding: 30px 30px 20px 30px;

}

.supporter-title {
    color: #4a4a4a;
}

.supporter-card {
    cursor: pointer;
    margin: 20px 0 20px;
}

.supporters-add-container {
    margin: -45px 150px 0 0;
}

.supporter-address {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
}

.supporter-created {
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    color: #757575;
}

.supporter-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    text-align: left;
    color: rgba(0, 0, 0, 0.54);
}

.supporters-filters-label {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.75;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
}

.supporters-empty {
    font-size: 18px;
}

.supporters-empty-top {
    font-size: 22px;
    font-weight: 500;
}

.supporters-campaign-name {
    font-weight: 500;
}

.supporter-sync-icon {
    width: 25px;
    height: auto;
}

.supporter-sync-status {
    font-size: 15px;
}

.supporter-hide-info {
    margin-left: -10px;
    color: #757575;
}

.supporter-letters-icon {
    width: 20px;
    height: auto;
    margin-top: 2px;
}
.support-records-container {
    padding: 30px;
}

.support-records-campaign-name {
    font-size: 16px;
}

.support-records-add-fab {
    z-index: 1100;
    position: fixed;
    right: 15px;
    top: 80px;
}
.home-toolbar-title {
    font-size: 20px;
    font-weight: 700;
    /*text-transform: uppercase;*/
    color: #fff;
    /*margin-left: 30px;*/
    text-align: left;
    margin-left: 10px;
}

.home-content-container {
    /*margin-left: var(--nav-width);*/
    height: 100vh;
}

.home-toolbar-menu-btn {
    margin-left: -10px !important;
}

.home-nav-container {
    /*position: fixed;*/
    /*top: 0;*/
    /*left: 0;*/
    z-index: 1101;
}

.home-toolbar-title-container {
    margin-left: 15px;
}

.home-main-content {
    background-color: var(--color-content-bg);
    /*padding-top: 65px;*/
}

.home-toolbar-logo {
    width: 265px;
    height: auto;
}


.login-container {
    display: flex;
    height: 100vh;
}

.login-background {
    background-image: url(/static/media/login-bg.f75426c5.jpg);
    background-size: cover;
    background-position: 50% 50%;
    position: absolute;
    -webkit-filter: blur(5px);
            filter: blur(5px);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.login-card {
    width: 500px;
    padding: 40px;
    z-index: 2;
}

.login-row {
    margin-top: 15px;
}

.login-buttons {
    margin-top: 30px;
}

.login-logo {
    width: 315px;
    height: auto;
}

.login-title {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 35px;
}

.login-helper-text {
    font-size: 16px;
    color: black;
    margin: 15px 0;
}
:root {
  /* Common Colors */
  --color-blue: #00b4e8;
  --color-green: #4db04a;
  --color-yellow: #ffd400;
  --color-darkest-gray: #363636;
  --color-gray: #6D6D6D;
  --color-medium-gray: #A4A4A4;
  --color-light-gray: #D5D5D5;
  --color-lightest-gray: #F2F4F5;
  --nav-width: 320px;
  --color-content-bg: #f5f5f5;
}

.roboto {
  font-family: 'Roboto', sans-serif;
}

.montserat {
  font-family: 'Montserrat', sans-serif;
}

.nothing {
  font-family: 'Nothing You Could Do', cursive;
}

.page-header {
  font-size: 28px;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.error-label {
  color: red;
  font-size: 14px;
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

@font-face {
  font-family: 'Montserrat';
  src: url(/static/media/Montserrat-Regular.2a2d37bf.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: url(/static/media/Montserrat-Medium.647f0e61.ttf);
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: url(/static/media/Montserrat-Bold.0915f0a8.ttf);
  font-weight: 700;
}
