.img-slide-container {
    /*padding: 20px;*/
}

.img-slide-inner {
    padding: 20px;
}

.img-slide-paper {
    padding: 5px;
}

.img-slide-img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 0%;
    width: 100%;
}