.supporter-info-confirmation-dialog-signature-container {
    /*width: 600px;*/
}

.supporter-info-confirmation-dialog {
    /*width: 90%;*/
}

.supporter-info-confirmation-dialog-title {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.87);
}

.supporter-info-confirmation-dialog-info {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
}

.supporter-info-confirmation-dialog-disclaimer {
    font-size: 10px;
    color: rgba(0, 0, 0, 0.54);
    position: absolute;
    bottom: 30px;
    left: 30px;
    right: 30px;
}

.supporter-info-confirmation-dialog-signature-x {
    font-size: 20px;
}

.supporter-info-confirmation-dialog-signature-label {
    font-size: 18px;
    line-height: 1.33;
    text-align: left;
    color: #a0a0a0;
    margin: 10px 0 10px;
}

/***
 Signature Shiz
 */

.m-signature-pad {
    position: relative;
    font-size: 10px;
    border: 1px solid black;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.m-signature-pad--body {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    /*border: 1px solid #f4f4f4;*/
}

.m-signature-pad--body
canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /*border-radius: 4px;*/
    /*box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;*/
}

.m-signature-pad--footer {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
    height: 40px;
}

.m-signature-pad--footer
.description {
    color: #C3C3C3;
    text-align: center;
    font-size: 1.2em;
    margin-top: 1.8em;
}

.m-signature-pad--footer
.button {
    position: absolute;
    bottom: 0;
}

.m-signature-pad--footer
.button.clear {
    left: 0;
    display: none;
}

.m-signature-pad--footer
.button.save {
    right: 0;
}


@media screen and (max-width: 2024px) {
    .m-signature-pad {
        height: 100%;
        min-height: 350px;
        /*margin: 5px;*/
    }
    #github {
        display: none;
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .m-signature-pad {
        /*margin: 20px;*/
    }
}

@media screen and (max-height: 320px) {
    .m-signature-pad--body {
        left: 0;
        right: 0;
        top: 0;
        bottom: 32px;
    }
    .m-signature-pad--footer {
        left: 20px;
        right: 20px;
        bottom: 4px;
        height: 28px;
    }
    .m-signature-pad--footer
    .description {
        font-size: 1em;
        margin-top: 1em;
    }
}